import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menu | Street Pub Menu
			</title>
			<meta name={"description"} content={"Bem-vindo ao coração gastronómico do Street Pub, onde todos os pratos e bebidas são criados para complementar os seus momentos de descontração e conversa."} />
			<meta property={"og:title"} content={"Menu | Street Pub Menu"} />
			<meta property={"og:description"} content={"Bem-vindo ao coração gastronómico do Street Pub, onde todos os pratos e bebidas são criados para complementar os seus momentos de descontração e conversa."} />
			<meta property={"og:image"} content={"https://dfastcomiss.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://dfastcomiss.com/img/3257747545.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://dfastcomiss.com/img/3257747545.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://dfastcomiss.com/img/3257747545.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://dfastcomiss.com/img/3257747545.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://dfastcomiss.com/img/3257747545.png"} />
			<meta name={"msapplication-TileImage"} content={"https://dfastcomiss.com/img/3257747545.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box border-radius="24px" margin="0px 0px 40px 0px">
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline1"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Cardápio{" "}
				</Text>
				<Text margin="20px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Bem-vindo ao coração gastronómico do Street Pub, onde todos os pratos e bebidas são criados para complementar os seus momentos de descontração e conversa.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="35px 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image src="https://dfastcomiss.com/img/menu-1.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Ales e cervejas artesanais
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Esboços locais: Descubra uma seleção rotativa de cervejas artesanais locais, cada uma com o seu carácter único.
						<br />
						<br />
						- Street Pub Signature Ale: Uma cerveja especial da casa, perfeita para descontrair e saborear os momentos mais suaves.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image src="https://dfastcomiss.com/img/menu-2.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Petiscos que aquecem o coração
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Prato de conforto: Um conjunto quente de clássicos de pub, incluindo batatas fritas estaladiças, anéis de cebola e molhos caseiros.
						<br />
						<br />
						- Tábua de queijos e charcutaria: Uma seleção cuidada de queijos finos e carnes curadas, ideal para partilhar em conversas descontraídas.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image src="https://dfastcomiss.com/img/menu-3.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Delícias sem álcool
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Cafés e chás artesanais: Escolha entre uma gama de cafés especiais e chás calmantes para se adequar ao seu estado de espírito.
						<br />
						<br />
						- Mocktails artesanais: Refrescantes e saborosos, os nossos mocktails são um deleite para os sentidos sem o zumbido.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://dfastcomiss.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Apenas o começo
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Este menu é apenas um vislumbre do que o espera no Street Pub. Atendemos a uma variedade de preferências alimentares e temos todo o gosto em satisfazer quaisquer necessidades específicas que possa ter. Não hesite em contactar-nos para obter informações mais detalhadas ou para se informar sobre as opções que se adequam às suas necessidades dietéticas.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});